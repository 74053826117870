import { all, fork, takeEvery, put, select, call } from "redux-saga/effects";
import { cellToBoundary, getResolution } from "h3-js";
import { getCells } from "../../helpers/api/realEstate";

//utils
import { hexResolution } from "../../utils/hexResolution";

//types
import { MapTypes } from "./constants";
import type { SagaIterator } from "@redux-saga/core";
import { LatLngBoundsLiteral } from "leaflet";
import { AxiosError } from "axios";
import { HexDataType } from "../../types/api/realEstateResponseTypes";

// let getHexControler: AbortController | null = null;

function* onGetCells(action: { type: MapTypes; payload: number }): SagaIterator {
  const zoom = action.payload;
  if (!hexResolution(zoom)) return;

  const boundaries = yield call(expandBounds, 1.3);

  try {
    // if (getHexControler) {
    //   getHexControler.abort();
    // }

    // getHexControler = new AbortController();
    const cells = yield call(getCells, { polygon: boundaries, res: hexResolution(zoom) || 8 });

    yield put({ type: MapTypes.SET_HEX_DATA, payload: cells.data });
  } catch (error) {
    const { name, message } = error as AxiosError;
    console.error(`${name}: ${message}`);
  }
}

function* seletedCellsHandler(action: { type: MapTypes; payload: HexDataType }): SagaIterator {
  const { type, payload } = action;
  const { selectedCells } = yield select((state) => state.mapReducer);
  let { res, cells } = selectedCells;

  if (type === MapTypes.SELECT_CELL) {
    if (res && res !== getResolution(payload.h3_hex)) {
      cells = [];
    }

    res = getResolution(payload.h3_hex);
    cells = [...cells, payload];
  }

  if (type === MapTypes.UNSELECT_CELL) {
    cells = cells.filter((cell: HexDataType) => cell.h3_hex !== payload.h3_hex);
  }

  yield put({ type: MapTypes.SELECTED_CELLS_HANDLER, payload: { res, cells } });
}

function* validateSelectedCells(): SagaIterator {
  const { selectedCells, mapZoom } = yield select((state) => state.mapReducer);
  const { res, cells } = selectedCells;
  let filteredCells;
  let newRes = res;

  if (mapZoom < 8) {
    filteredCells = [];
    newRes = null;
  } else {
    const [NW, , SE] = yield call(expandBounds, 0.3);

    filteredCells = cells
      .map((cell: HexDataType) => {
        const [x, y] = cellToBoundary(cell.h3_hex)[0];

        const condition1 = Math.min(NW[0], SE[0]) <= x;
        const condition2 = Math.max(NW[0], SE[0]) >= x;
        const condition3 = Math.min(NW[1], SE[1]) <= y;
        const condition4 = Math.max(NW[1], SE[1]) >= y;

        if (condition1 && condition2 && condition3 && condition4) return cell;
        return null;
      })
      .filter((cell: string | null) => cell);
  }

  yield put({
    type: MapTypes.SELECTED_CELLS_HANDLER,
    payload: { res: newRes, cells: filteredCells },
  });
}

function* expandBounds(multiplier: number): SagaIterator {
  const { mapBoundaries } = yield select((state) => state.mapReducer);
  const expandBounds = mapBoundaries.pad(multiplier);
  const NW = expandBounds.getNorthWest();
  const NE = expandBounds.getNorthEast();
  const SE = expandBounds.getSouthEast();
  const SW = expandBounds.getSouthWest();

  const bounds: LatLngBoundsLiteral = [
    [NW.lat, NW.lng],
    [NE.lat, NE.lng],
    [SE.lat, SE.lng],
    [SW.lat, SW.lng],
  ];
  return bounds;
}

export function* watchCells() {
  yield takeEvery(MapTypes.GET_CELLS_DATA, onGetCells);
}

export function* watchSelectCell() {
  yield takeEvery(MapTypes.SELECT_CELL, seletedCellsHandler);
}

export function* watchUnselectCell() {
  yield takeEvery(MapTypes.UNSELECT_CELL, seletedCellsHandler);
}

export function* watchSetBoundery() {
  yield takeEvery(MapTypes.SET_MAP_BOUNDARIES, validateSelectedCells);
}

function* mapSaga() {
  yield all([
    fork(watchCells),
    fork(watchSelectCell),
    fork(watchUnselectCell),
    fork(watchSetBoundery),
  ]);
}

export default mapSaga;
