export enum MapTypes {
  SET_MAP_ZOOM = "@@map/SET_MAP_ZOOM",
  SET_MAP_BOUNDARIES = "@@map/SET_MAP_BOUNDARIES",
  GET_CELLS_DATA = "@@map/GET_CELLS_DATA",
  SET_HEX_DATA = "@@map/SET_HEX_DATA",
  HEX_LOADING = "@@map/HEX_LOADING",
  SELECT_CELL = "@@map/SELECT_CELL",
  UNSELECT_CELL = "@@map/UNSELECT_CELL",
  SELECTED_CELLS_HANDLER = "@@map/SELECTED_CELLS_HANDLER",
  //test start
  SEARCH_CELL = "@@map/SEARCH_CELL",
  //test end
  SET_VISUALIZATION_MODE = "@@map/SET_VISUALIZATION_MODE",
}
