import { AnyAction } from "redux";
import { MapTypes } from "./constants";
import { VISUALIZATION_MODES } from "../../types/map/visualizationTypes";

const initialState = {
  mapZoom: 10,
  mapBoundaries: null,
  hexData: null,
  hexLoadingState: false,
  selectedCells: { res: null, cells: [] },
  // test start
  searchCellResult: null,
  // test end
  visualizationMode: VISUALIZATION_MODES[0],
  LEGEND_RANGES: {
    avg_price_per_sqm: [
      { range: "0 - 1000 $/m²", value: [0, 1000], color: "#E3F2FD" },
      { range: "1000 - 2000 $/m²", value: [1000, 2000], color: "#90CAF9" },
      { range: "2000 - 3000 $/m²", value: [2000, 3000], color: "#42A5F5" },
      { range: "3000 - 4000 $/m²", value: [3000, 4000], color: "#1E88E5" },
      { range: "4000+ $/m²", value: [4000, Infinity], color: "#1565C0" },
    ],
    avg_area: [
      { range: "0 - 50 m²", value: [0, 50], color: "#E8F5E9" },
      { range: "50 - 100 m²", value: [50, 100], color: "#A5D6A7" },
      { range: "100 - 150 m²", value: [100, 150], color: "#66BB6A" },
      { range: "150 - 200 m²", value: [150, 200], color: "#43A047" },
      { range: "200+ m²", value: [200, Infinity], color: "#2E7D32" },
    ],
    count: [
      { range: "1 - 10", value: [1, 10], color: "#FFF3E0" },
      { range: "11 - 50", value: [11, 50], color: "#FFB74D" },
      { range: "51 - 100", value: [51, 100], color: "#FFA726" },
      { range: "101 - 500", value: [101, 500], color: "#FB8C00" },
      { range: "500+", value: [500, Infinity], color: "#EF6C00" },
    ],
    median_price: [
      { range: "1 - 25 000", value: [1, 25000], color: "#99FFFD" },
      { range: "25 001 - 50 000", value: [25001, 50000], color: "#76D6D4" },
      { range: "50 001 - 100 000", value: [50001, 100000], color: "#54AFAD" },
      { range: "100 001 - 500 000", value: [100001, 500000], color: "#308987" },
      { range: "500 000+", value: [500000, Infinity], color: "#016563" },
    ],
    median_price_per_sqm: [
      { range: "1 - 100", value: [1, 100], color: "#FF8FBE" },
      { range: "101 - 500", value: [101, 500], color: "#D86C96" },
      { range: "501 - 1500", value: [501, 1500], color: "#B24B70" },
      { range: "1501 - 3000", value: [1501, 3000], color: "#8C294C" },
      { range: "3000+", value: [3000, Infinity], color: "#67002B" },
    ],
  },
  contrastColors: {
    avg_price_per_sqm: "#C06F15",
    avg_area: "#7D2D78",
    count: "#0083EF",
    median_price: "#650002",
    median_price_per_sqm: "#00673C",
  },
};

const mapReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case MapTypes.SET_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload,
      };
    case MapTypes.SET_MAP_BOUNDARIES:
      return {
        ...state,
        mapBoundaries: action.payload,
      };
    case MapTypes.SET_HEX_DATA:
      return {
        ...state,
        hexData: action.payload,
      };
    case MapTypes.SELECTED_CELLS_HANDLER:
      return {
        ...state,
        selectedCells: action.payload,
      };
    case MapTypes.HEX_LOADING:
      return {
        ...state,
        hexLoadingState: action.payload,
      };
    case MapTypes.SEARCH_CELL:
      return {
        ...state,
        searchCellResult: action.payload,
      };
    case MapTypes.SET_VISUALIZATION_MODE:
      return {
        ...state,
        visualizationMode: action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
