// the key is the resolution of the map, the value is the corresponding H3 resolution
const resolution: { [key: number]: number } = {
  8: 4,
  9: 4,
  10: 5,
  11: 6,
  12: 7,
  13: 7,
  14: 8,
  15: 8,
  16: 8,
};

export const hexResolution: (zoom: number) => number | null = (zoom) => {
  return resolution[zoom] ? resolution[zoom] : null;
};

//test start
export const resolutionFromHex: (hexZoom: number) => string = (hexZoom) => {
  for (const key in resolution) {
    if (resolution[key] === hexZoom) return key;
  }

  return "8";
};
//test end
