import jwtDecode from "jwt-decode";
import axios from "axios";
import qs from "qs";
import i18next from "i18next";

import config from "../../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

// intercepting to set language in request
axios.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {};

    // Always check for auth token and set it if available
    const user =
      getUserFromCookie() || JSON.parse(localStorage.getItem(AUTH_SESSION_KEY) || "null");
    if (user?.token) {
      config.headers["Authorization"] = `Bearer ${user.token}`;
    }

    config.headers["Accept-Language"] = i18next.language || "en";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AUTH_SESSION_KEY = "InvestViews_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

class APICore {
  /**
   * Fetches data from given url
   */
  get = (url: string, params: any, config?: any) => {
    let response;
    if (params) {
      const queryString = qs.stringify(params);
      response = axios.get(`${url}?${queryString}`, config);
    } else {
      response = axios.get(`${url}`, config);
    }
    return response;
  };

  getFile = (url: string, params: any, config?: any) => {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob", ...config });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: any) => {
    return axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    try {
      const user = this.getLoggedInUser();

      if (!user || !user.token) {
        return false;
      }

      const decoded: any = jwtDecode(user.token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        console.warn("Access token expired");
        // Clean up expired token
        this.setLoggedInUser(null);
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error verifying authentication:", error);
      return false;
    }
  };

  setLoggedInUser = (session: any, rememberMe: boolean = false) => {
    if (session) {
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    } else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
      localStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  getLoggedInUser = () => {
    const sessionUser = sessionStorage.getItem(AUTH_SESSION_KEY);
    const localUser = localStorage.getItem(AUTH_SESSION_KEY);

    // First check session storage
    if (sessionUser) {
      return JSON.parse(sessionUser);
    }

    // If not in session but exists in local storage, sync it to session
    if (localUser) {
      const user = JSON.parse(localUser);
      // Only sync if token is still valid
      const decoded: any = jwtDecode(user.token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp > currentTime) {
        sessionStorage.setItem(AUTH_SESSION_KEY, localUser);
        return user;
      } else {
        // Clean up expired token
        localStorage.removeItem(AUTH_SESSION_KEY);
        return null;
      }
    }

    return null;
  };

  setUserInSession = (modifiedUser: any) => {
    const sessionUser = sessionStorage.getItem(AUTH_SESSION_KEY);
    const localUser = localStorage.getItem(AUTH_SESSION_KEY);

    if (sessionUser) {
      const { token, user } = JSON.parse(sessionUser);
      this.setLoggedInUser({ token, ...user, ...modifiedUser }, false);
    } else if (localUser) {
      const { token, user } = JSON.parse(localUser);
      this.setLoggedInUser({ token, ...user, ...modifiedUser }, true);
    }
  };

  logout = () => {
    sessionStorage.removeItem(AUTH_SESSION_KEY);
    localStorage.removeItem(AUTH_SESSION_KEY);
    setAuthorization(null);
  };
}

/*
Check if token available in session
*/
const user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization, AUTH_SESSION_KEY };
