import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

interface UserData {
  payload: {
    username: string;
    password: string;
    name: string;
    email: string;
    rememberMe?: boolean;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* login({ payload: { email, password, rememberMe = false } }: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { email, password });

    // Try to get token from either response headers or body
    const token = response.headers?.authorization || response.data?.token;
    const user = response.data?.user || response.data;

    if (!token) {
      throw new Error("No authorization token received");
    }

    // Store session
    api.setLoggedInUser({ token, ...user }, rememberMe);
    setAuthorization(`Bearer ${token}`);

    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, { token, user }));
  } catch (error: any) {
    let message = "An error occurred during login";

    if (error.response) {
      // Use the error message from the API response if available
      message =
        error.response.data?.message ||
        error.response.data ||
        "Something went wrong. Please contact support.";

      // Log the full error response for debugging
      console.error("Login error response:", {
        status: error.response.status,
        data: error.response.data,
      });
    } else if (error.request) {
      message = "No response from server. Please try again.";
      console.error("No response received:", error.request);
    } else {
      message = error.message || "Login failed. Please try again.";
      console.error("Login error:", error);
    }

    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, message));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    api.logout();
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({ payload: { name, email, password } }: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { name, email, password });
    const user = response.data.user;
    user.token = response.headers.authorization;
    api.setLoggedInUser(user);
    setAuthorization(user.token);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
  ]);
}

export default authSaga;
